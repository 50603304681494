<template>
    <b-overlay :show="showLoader">
        <b-card>
            <div style="font-size: 2rem; color: #189dd4; font-weight: bold;" class="mb-2">
                e-sestanek
            </div>

            <div style="font-size: 1.5rem; font-weight: bold;" class="mb-2">
                Prihajajoči e-sestanki
            </div>
        
            <div class="mt-2">
                <div class="row p-1" style="font-weight: 900; font-size: 1.3rem; background: #bdd9ef;">
                    <div class="col-4">
                        Sestanek
                    </div>
                    <div class="col-2">
                        Datum
                    </div>
                    <div class="col-2">
                        Ura začetka
                    </div>
                </div>
                <div v-if="rooms && rooms.length > 0">
                    <div class="row p-1 d-flex align-items-center" v-for="room in rooms" :key="'soba_' + room.id" style="background: #f9fbfb; border-bottom: 1px solid #4a4b4a;">
                        <div class="col-4">
                            {{room.name}}
                        </div>
                        <div class="col-2">
                            {{getDate(new Date(room.start_time))}}
                        </div>
                        <div class="col-2">
                            {{getHours(new Date(room.start_time))}}
                        </div>
                        <div class="col-2 d-flex justify-content-end">
                            <b-button class="d-flex align-items-center" @click="joinMeeting(room)">
                                Začni sestanek <fa icon="video" class="ml-1" />
                            </b-button>
                        </div>
                        <div class="col-2 d-flex justify-content-end">
                            <b-button variant="danger" @click="endMeeting(room)">
                                Zaključi sestanek
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-5 mb-2" style="font-size: 1.5rem;">
                Dodaj Sestanek
            </div>
            <div class="row d-flex justify-content-between">
                <div class="col-12"> 
                    <b-form-group label="Upravnik">
                        <validation-provider #default="{ errors }" name="upravnik" rules="required">
                            <Select v-model="selectedManager" :options="managersList" text="Upravnik" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <!-- <b-form-group v-if="selectedManager !== null" >
                        <b-form-checkbox v-if="selectedManager" switch v-model="units_checkbox">Izberi objekte, ki bojo prejeli sporočilo</b-form-checkbox>
                    </b-form-group> -->
                    <b-form-group v-if="selectedManager && buildings" label="Objekti">
                        <validation-provider #default="{ errors }" name="objekti" rules="required">
                            <Select v-model="building" label="naziv" :options="buildings" text="Objekti"/>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </div>
              
           
                <div class="col-5">
                    <b-form-group label="Ime sestanka">  
                        <b-form-input v-model="meetingName" placeholder="Enter meeting name"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-4">
                    <b-form-group label="Datum sestanka">
                        <b-form-datepicker v-model="time" class="mb-2"></b-form-datepicker>
                    </b-form-group>
                </div>
                <div class="col-3">
                    <b-form-group label="Ura sestanka">
                        <b-form-timepicker :hour12="false" v-model="hour" class="mb-2" required></b-form-timepicker>
                    </b-form-group>
                </div>
            </div>
            <b-button @click="createMeeting()" :disabled="!(hour && time && meetingName && building && building.sifra)">Ustvari sestanek</b-button>
        </b-card>
    </b-overlay>
</template>

<script>
    import { BFormInput, BButton, BFormDatepicker, BFormGroup, BCard, BFormTimepicker, BOverlay  } from 'bootstrap-vue'
    import Select from '@/views/Components/Select.vue'
    import { required } from '@validations'

    import { ValidationProvider } from 'vee-validate'
    // import { required } from '@validations'
    export default {
        components:{
            BFormInput,
            BButton,
            // BFormCheckbox,
            BFormDatepicker,
            BFormGroup,
            BCard,
            BFormTimepicker,
            BOverlay,
            Select,
            ValidationProvider
        },
        data() {
            return {
                required,
                selectedManager: null,
                managersList: this.$store.getters['user/managers'],
                building: null,
                showLoader: false,
                meetingName: null,
                time: null,
                finishedRooms: null,
                rooms: null,
                hour: null,
                buildings: null,
                buildingsList: null,
                units_checkbox: false
            }
        },
        watch: {
            units_checkbox(val) {
                if (val === true) {
                    this.getBuildings()
                }
            },
            selectedManager(val) {
                if (val) {
                    this.getBuildings()
                }
            }
        },
        methods:{
            getBuildings() {
                const thisIns = this

                if (this.selectedManager !== undefined && this.selectedManager !== '') {
                    this.$http.get(`/api/management/v1/objekti/${this.selectedManager}`)
                        .then(res => {
                            thisIns.buildings = res.data
                        }).catch(function(error) {
                            thisIns.$printError(error.message)
                        })
                }
            },
            getDate(date) {
                return `${date.getDate() }.${  date.getMonth() + 1  }.${  date.getFullYear()}`
            },
            getHours(date) {
                const minutes = date.getMinutes()
                let stringMinutes = ''

                if (minutes < 10) {
                    stringMinutes = `0${  minutes}`
                } else {
                    stringMinutes = minutes
                }

                return `${date.getHours()  }:${stringMinutes}`
            },
            joinMeeting(room) {
                this.$router.push({ name: 'jitsi_meeting', params: { 'room_id': room.id}})
            },
            async endMeeting(room) {
                this.showLoader = true
                try {
                    await this.$http.post('/api/management/v1/jitsi/end_meeting', room)
                    await this.updateMeetings()
                } catch (err) {
                    this.$printError('Prislo je do napake')
                } finally {
                    this.showLoader = false
                }
            },
            getHoursMinutes(time) {
                const splitedTime = time.split(':')
                return {
                    hour: splitedTime[0], 
                    minute: splitedTime[1]
                }
            },
            async createMeeting() {
                this.showLoader = true
                try {
                    const startTime = new Date(this.time)
                    const time = this.getHoursMinutes(this.hour)
                    startTime.setHours(time.hour, time.minute, 0, 0)

                    const endTime = startTime
                    endTime.setHours(endTime.getHours() + 1)

                    const payload = {
                        'name': this.meetingName,
                        'start_time': startTime,
                        'expiration_time': endTime
                    }

                    if (this.building) {
                        payload['building'] = this.building.sifra

                    }

                    await this.$http.post('/api/management/v1/jitsi/createMeeting', payload)

                    await this.updateMeetings()
                } catch (err) {
                    this.$printError('Prislo je do napake')
                } finally {
                    this.showLoader = false
                }
            },
            async updateMeetings() {
                this.showLoader = true
                try {
                    const data = await this.$http.get('/api/management/v1/jitsi/upcoming_meetings')
                    this.rooms = data.data                    
                } catch (err) {
                    this.$printError('Prislo je do napake')
                } finally {
                    this.showLoader = false
                }
            }
        },
        mounted() {
            this.updateMeetings()
        }
    }
</script>

<style scoped>
</style>